<template>
  <a href="javascript:;" @click="link" :to="to">{{labelName}} <i v-if="match" :class="'icon-arrow-'+(inverseSort?'up':'down')"></i></a>
</template>

<script>
import Gen from '@helper/Gen';

export default {
  props:{
    name:String,
    label:String,
  },
  computed:{
    page(){ return this.$root.page },
    match(){ if(!this.$route.query.sort) return false; var ex = this.$route.query.sort.split("-"); return ex[0]==this.name ? this.name : "" },
    inverseSort(){
      if(!this.$route.query.sort) return ""
      var ex = this.$route.query.sort.split("-")
      if(ex[0]!=this.name) return ""
      return typeof ex[1] == "undefined" ? "-" : ""
    },
    query(){
      return Object.assign(Gen.clone(this.$route.query),{sort:this.name+this.inverseSort})
    },
    labelName(){
      if(this.label) return this.label;
      return this.page.fields[this.name]
    },
    to(){
      return {name:this.page.Name,query:this.query}
    }
  },
  methods:{
    link(){
      this.page.$router.push(this.to)
    }
  }
}
</script>

<style scoped lang="scss">
a{
  white-space: nowrap;
  i{
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    font-size: 12px;
  }
}
</style>
